import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import { rhythm } from "../../typography"
import { PostInfo } from "../markdown-remark-types"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import Image from "gatsby-image"
import { transitionDuration } from "./layout";

const PostHeading = styled.div`
  font-size: 0.8em;
  margin-bottom: ${rhythm(1 / 4)};
  display: none;
  position: relative;
  height: 70px;
  background-color: white;
  top: -200px;
`

const PostDate = styled.span`
  color: #bbb;
  padding-left: 0.5rem;
`

const GridRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
`

const Post = styled.div`
  padding: 4px;

  &:hover {
    filter: brightness(1.05);
  }
`

const GallerySection = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 0px;
`

// tslint:disable-next-line:prettier
export const chunks = <T, >(input: T[], chunkSize: number): T[] => {
  const arrayLen = input.length
  const output: T[] = []
  for (let index = 0; index < arrayLen; index += chunkSize) {
    const c = input.slice(index, index + chunkSize)
    output.push(c as any)
  }
  return output
}

export const renderRow = (posts: ReadonlyArray<PostInfo>) => {
  return <GridRow>{posts.map((post: PostInfo) => renderPost(post))}</GridRow>
}

const renderPost = (post: PostInfo) => {
  return (
    <Post key={post.node.id}>
      <AniLink fade to={post.node.fields.slug} duration={transitionDuration}>
        <Image
          title={"Derp"}
          backgroundColor={`#040e18`}
          fadeIn={true}
          fluid={
            (post.node.frontmatter.featuredImage! as any).childImageSharp.fluid
          }
        />
      </AniLink>
      <PostHeading>
        {post.node.frontmatter.title}
        <PostDate>{post.node.frontmatter.date}</PostDate>
      </PostHeading>
    </Post>
  )
}

export default () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "//projects//" } }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            fields {
              slug
            }
            id
            frontmatter {
              title
              date(formatString: "DD MMMM, YYYY")
              featuredImage {
                childImageSharp {
                  fluid(maxWidth: 300) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            excerpt
          }
        }
      }
    }
  `)
  return (
    <GallerySection>
      {data.allMarkdownRemark.edges.map((post: PostInfo) => {
        return renderPost(post)
      })}
    </GallerySection>
  )
}
